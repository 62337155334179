import { css } from '@emotion/react';
import { rwdrc2 } from '~/configs/rwdrc';
/**
 * - 視野 desktop (NO)
 * - 視野 iPad (YES)
 * - 視野 mobile (YES)
 */
export const createMobileAndIPadCss = (rootCss) => css `
    @media screen and (max-width: ${rwdrc2.tablet.max + 'px'}) {
      ${rootCss}
    }
  `;
