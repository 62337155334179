import { css } from '@emotion/react';
import { Burger } from '@mantine/core';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const SidebarHeader = (props) => {
    return (<div className={props.className} css={css `
        display: flex;
        align-items: center;
        height: 100%;
      `}>
      <Burger opened={pageStore.drawer1.opened} onClick={event => {
            pageStore.drawer1.opened = !pageStore.drawer1.opened;
        }} css={css `
          width: 48px;
          height: 48px;
          padding: 6px;
          cursor: pointer;
          z-index: 10000;
        `}/>
    </div>);
};
