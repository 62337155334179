import { css } from '@emotion/react';
import { Fragment } from 'react';
import { clas } from '~/utils/clas';
export const _GridArea = (props) => {
    return (<Fragment>
      <div className={clas([props.name, props.className])} css={css `
          grid-area: ${props.name};
        `}>
        {props.children}
      </div>
    </Fragment>);
};
_GridArea.displayName = 'FrTemplate/v2/components/GridArea';
