import { Box, Collapse, Group, ThemeIcon, UnstyledButton, rem } from '@mantine/core';
import { IconArrowBadgeRightFilled, IconChevronRight } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import { clas as cx } from '~/utils/clas';
import * as classes from './SidebarLinks.module.scss';
import { AppLink2 } from '~/components/AppLink2';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { Permission } from '~/pages/strategy_center_admin/_components/Permission';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
import { css } from '@emotion/react';
export function SidebarLinks({ icon: Icon, label, initiallyOpened, links, }) {
    const hasLinks = Array.isArray(links);
    const [opened, setOpened] = useState(initiallyOpened || false);
    const items = (hasLinks ? links : []).map(link => {
        const shouldHidden = link.functionHidden?.includes(fr_agents.agentProduct);
        const shouldVisibleByRole = link.functionVisibleByRole || pageConfig.adminRoleOptions.map(role => role.value);
        // 獲取當前路徑並分割成段
        const currentPath = window.location.pathname;
        const currentPathSegments = currentPath.split('/');
        const currentPathLastSegment = currentPathSegments[currentPathSegments.length - 1];
        // 獲取鏈接路徑並分割成段
        const linkPathSegments = link.link.split('/');
        const linkPathLastSegment = linkPathSegments[linkPathSegments.length - 1];
        // 檢查當前頁面路徑是否包含鏈接路徑的最後一段
        const isCurrentPage = currentPathLastSegment.includes(linkPathLastSegment);
        if (shouldHidden) {
            return null;
        }
        return (<Permission key={link.label} roles={shouldVisibleByRole}>
        <AppLink2 className={classes.link} href={link.link} css={css `
            padding: 4px 0;
            //padding-left: ${isCurrentPage ? '16px' : null};
            color: ${isCurrentPage ? '#1b6dda' : '#282828'};
            &:hover {
              color: ${isCurrentPage ? '#3d7cd0' : '#3d7cd0'};
            }
          `}>
          {isCurrentPage ? <IconArrowBadgeRightFilled size={18}/> : null}
          {link.label}
        </AppLink2>
      </Permission>);
    });
    // 如果全部 links 因 config 因素而全部都被隱藏，則 links group 也返回 null 一起隱藏
    if (items.every(item => item === null)) {
        return null;
    }
    return (<Fragment>
      <UnstyledButton onClick={() => {
            setOpened(o => !o);
        }} className={cx(classes.control)}>
        <Group className={cx(classes.group)}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant='light' size={28}>
              <Icon style={{ width: rem(18), height: rem(18) }}/>
            </ThemeIcon>
            <Box ml='md'>{label}</Box>
          </Box>
          {hasLinks && (<IconChevronRight className={classes.chevron} stroke={1.5} style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
            }}/>)}
        </Group>
      </UnstyledButton>

      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </Fragment>);
}
