import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { MdDarkMode, MdOutlineWbSunny } from 'react-icons/md';
import { FrTemplate } from '~/modules/SDK/FrTemplate/v2/FrTemplate';
import { clas } from '~/utils/clas';
export const _ThemeToggle = (props) => {
    const colorSchema = FrTemplate.useCtx().colorSchema;
    const key = colorSchema.useThemeKey();
    const DarkModeIcon = props.darkModeIcon || MdDarkMode;
    const LightModeIcon = props.lightModeIcon || MdOutlineWbSunny;
    return (<Fragment>
      {key === 'dark' ? (<LightModeIcon className={clas(_ThemeToggle.classes.root)} css={iconButtonCss} onClick={event => {
                colorSchema.toggle();
            }}/>) : (<DarkModeIcon className={clas(_ThemeToggle.classes.root)} css={iconButtonCss} onClick={event => {
                colorSchema.toggle();
            }}/>)}
    </Fragment>);
};
_ThemeToggle.classes = {
    root: styled.div ``,
};
const iconButtonCss = css `
  cursor: pointer;
  width: 36px;
  height: 36px;
`;
