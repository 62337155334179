import { css } from '@emotion/react';
import { values } from 'lodash';
import { AgentProduct } from '~/configs/AgentProduct';
import { FrTemplate } from '~/modules/SDK/FrTemplate/v2/FrTemplate';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { StrategyOverviewControlBar } from '~/pages/strategy_center_admin/strategy-overview/_components/StrategyOverviewControlBar';
export const pageCssset = values({
    /** 公版樣式 */
    common: css `
    #__next {
      .ag-theme-alpine {
        min-height: 500px;
      }

      .ag-cell {
        display: flex;
        align-items: center;
      }

      ${StrategyOverviewControlBar.classes.root} {
        ${StrategyOverviewControlBar.classes.section} {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .mantine-SimpleGrid-root {
        gap: 0;
      }

      .mantine-Chip-root {
        display: inline-block;
      }
    }

    #__next {
      ${FrTemplate.LayoutRebase202309.classes.root} {
        &${FrTemplate.LayoutRebase202309.classes.__light} {
          ${FrTemplate.ThemeToggle.classes.root} {
            color: orange;
          }
        }
      }
    }

    #__next {
      ${FrTemplate.LayoutRebase202309.classes.root} {
        &${FrTemplate.LayoutRebase202309.classes.__dark} {
          ${FrTemplate.ThemeToggle.classes.root} {
            color: red;
          }
        }
      }
    }
  `,
    /** 富邦客製覆蓋公版 */
    [AgentProduct['futuresai@strategy_center_admin']]: fr_agents.agentProduct === AgentProduct['futuresai@strategy_center_admin'] &&
        css `
      #__next {
      }

      #__next {
        ${FrTemplate.LayoutRebase202309.classes.root} {
          &${FrTemplate.LayoutRebase202309.classes.__light} {
            ${FrTemplate.ThemeToggle.classes.root} {
              color: #1900ff;
            }
          }
        }
      }

      #__next {
        ${FrTemplate.LayoutRebase202309.classes.root} {
          &${FrTemplate.LayoutRebase202309.classes.__dark} {
            ${FrTemplate.ThemeToggle.classes.root} {
              color: #00eaff;
            }
          }
        }
      }
    `,
    /** 凱基客製覆蓋公版 */
    // ...略
    // fr_agents.agentProduct === AgentProduct['XXX@strategy_center_admin'] && css``
});
