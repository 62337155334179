import Head from 'next/head';
import Script from 'next/script';
import { Fragment } from 'react';
import { component } from '~/utils/component';
export const _HeadRebase = component(props => {
    return (<Fragment>
      <Head>
        <title>{props.title}</title>
        <meta name='viewport' content='initial-scale=1, width=device-width, maximum-scale=1'/>

        {props.favicon && (<Fragment>
            <link rel='shortcut icon' href={`./${props.favicon}`}/>
            <link rel='icon' sizes='192x192' href={`./${props.favicon}`}/>
            <link rel='apple-touch-startup-image' href={`./${props.favicon}`}/>
            <link rel='apple-touch-icon' href={`./${props.favicon}`}/>
          </Fragment>)}
      </Head>

      {props.googleAnalytics?.analyticsID && (<Fragment>
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${props.googleAnalytics.analyticsID}`}></Script>
          <script dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${props.googleAnalytics.analyticsID}');
                `,
            }}></script>
        </Fragment>)}
    </Fragment>);
});
