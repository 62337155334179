import { Fragment } from 'react';
import { DemoCode } from '~/components/DemoCode';
import { FrTemplate } from '~/modules/SDK/FrTemplate/v2/FrTemplate';
import { component } from '~/utils/component';
export const _MediaRender = component(props => {
    const media = FrTemplate.useMedia();
    const shouldGivenProps = !props.isPc && !props.isPhone;
    if (shouldGivenProps) {
        return <DemoCode>{`請傳入 props.isPc 或 props.isPhone`}</DemoCode>;
    }
    return (<Fragment>
      {props.isPc && media.isPc && <Fragment>{props.children}</Fragment>}
      {props.isPhone && !media.isPc && <Fragment>{props.children}</Fragment>}
    </Fragment>);
});
