import { css } from '@emotion/react';
import { ScrollArea, } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useProxy } from 'valtio/utils';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { SidebarLinks } from '~/pages/strategy_center_admin/_components/SidebarLinks/SidebarLinks';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { clas as cx } from '~/utils/clas';
import * as classes from './PageSidebar.module.scss';
import { ENV } from '~/configs/ENV';
import styled from '@emotion/styled';
import { fill_horizontal_cross_center, fill_vertical_main_center, jc, } from '~/modules/AppLayout/FlexGridCss';
PageSidebar.classes = classes;
export function PageSidebar(props) {
    const me = useProxy(pageStore.me);
    const links = pageConfig.sidebarItems.map(link => {
        return (<SidebarLinks {...link} key={link.label}/>);
    });
    return (<classess.container>
      <div className={cx(classes.header)}>
        <div className={cx(classes.logo)}>
          <img className={cx(classes.logoImg)} src={fr_agents.config.favicon}/>
        </div>
      </div>
      <ScrollArea className={cx(classes.links)}>
        <div className={cx(classes.linksInner)}>{links}</div>
      </ScrollArea>

      <classess.userInfo>
        <div>版號 {ENV.DATETIME.substring(0, 10) + '-' + ENV.COMMITHASH.substring(0, 4)}</div>
        <div>帳號 {`${me.current?.account} (${me.current?.name})`}</div>
        <div>權限 {me.current?.role}</div>
      </classess.userInfo>

      <classess.logout.body onClick={event => {
            me.logout();
        }}>
        <IconLogout color='#228be6' size={24} css={css `
            cursor: pointer;
          `}/>
        <classess.logout.text>登出</classess.logout.text>
      </classess.logout.body>
    </classess.container>);
}
const classess = {
    container: styled.div `
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 80px 1fr 96px 80px;
    background-color: #fdfdfd;
    border-right: 1px solid #bababa;
    padding: 4px 8px;
    box-sizing: border-box;
  `,
    userInfo: styled.div `
    ${fill_vertical_main_center};
    ${jc.spaceAround};
    height: 72px;
    font-size: 13px;
    font-weight: 500;
    background-color: #f3f3f3;
    color: #252525;
    padding: 4px;
    border-radius: 4px;
    box-sizing: border-box;
  `,
    logout: {
        body: styled.div `
      ${fill_horizontal_cross_center};
      height: 40px;
      gap: 16px;
      cursor: pointer;
      &:hover {
        background-color: #eff6fd;
        border-radius: 4px;
      }
    `,
        text: styled.span ``,
    },
};
