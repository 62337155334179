import { css } from '@emotion/react';
/**
 * 修正 ios safari 底下那個動來動去的網址列，會造成滿版高設計的投顧，底下老是被蓋住的問題
 *
 * Avoid Chrome to see Safari hack
 *
 * @see https://github.com/postcss/postcss-100vh-fix
 */
export const _iosTouchCalloutHack = css `
  html,
  body,
  #__body,
  #__next {
    @supports (-webkit-touch-callout: none) {
      min-height: fill-available;
    }
  }
`;
