import { expectType } from '~/utils/tsd';
export const _getAreasName = (gridName) => {
    const defaultAreas = [
        'Drawer1',
        'Drawer2',
        'Col1',
        'Col2',
        'Col3',
        'Col4',
        'Col5',
        'Col6',
        'Row1',
        'Row2',
        'Row3',
        'Row4',
        'Row5',
        'Row6',
        'Sidebar1',
        'Sidebar2',
        'Header',
        'Topbar',
        'Main',
        'Footer',
    ];
    return defaultAreas.reduce((areasObj, name) => {
        areasObj[name] = `${gridName}__${name}`;
        return areasObj;
    }, {
        Root: `${gridName}`,
    });
};
/* istanbul ignore next */
function Testing() {
    function Test1() {
        const areasName = _getAreasName('RootLayout');
        expectType(areasName.Root);
        expectType(areasName.Drawer1);
        expectType(areasName.Drawer2);
        expectType(areasName.Col1);
        expectType(areasName.Col2);
        expectType(areasName.Col3);
        expectType(areasName.Col4);
        expectType(areasName.Col5);
        expectType(areasName.Col6);
        expectType(areasName.Row1);
        expectType(areasName.Row2);
        expectType(areasName.Row3);
        expectType(areasName.Row4);
        expectType(areasName.Row5);
        expectType(areasName.Row6);
        expectType(areasName.Sidebar1);
        expectType(areasName.Sidebar2);
        expectType(areasName.Topbar);
        expectType(areasName.Header);
        expectType(areasName.Footer);
        expectType(areasName.Main);
        // @ts-expect-error 它沒有這個屬性
        expectType(areasName.fjaijgij);
    }
}
