import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MantineProvider, Paper } from '@mantine/core';
import { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTimeout } from 'react-use';
import { FrTemplate } from '~/modules/SDK/FrTemplate/v2/FrTemplate';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { clas } from '~/utils/clas';
/**
 * 這支 hooks 是在解決 cloud build 時候，mantine 樣式完全丟失的問題
 *
 * 也許因為某些 state 是從 localStore 來的，所以造成 re-render 好像有問題
 */
function useEnsureSsrStyles() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [getHasTimeout, cancelTimeout, resetTimeout] = useTimeout(50);
    useEffect(() => {
        setHasLoaded(() => true);
    }, [getHasTimeout()]);
    if (!hasLoaded || !getHasTimeout()) {
        return false;
    }
    return hasLoaded;
}
export const _LayoutRebase202309 = (props) => {
    const theme = FrTemplate.useCtx().colorSchema.useThemeKey();
    if (!useEnsureSsrStyles())
        return <div>Preparing Layouts...</div>;
    return (<Fragment>
      <FrTemplate.HeadRebase {...fr_agents.config}/>
      <FrTemplate.CssRebase />

      <ToastContainer autoClose={5000} position='top-center' theme={theme || 'light'} css={css `
          .Toastify__toast.Toastify__toast-theme--dark {
            border: 1px solid #fff;
          }
        `}/>

      <MantineProvider theme={{
            colorScheme: theme || 'light',
        }}>
        <Paper css={css `
            border-radius: 0;
            padding: 0;
            margin: 0;
            min-height: 100%;
          `} className={clas([
            _LayoutRebase202309.classes.root,
            theme === 'dark' && _LayoutRebase202309.classes.__dark,
            theme === 'light' && _LayoutRebase202309.classes.__light,
        ])}>
          {props.children}
        </Paper>
      </MantineProvider>
    </Fragment>);
};
_LayoutRebase202309.classes = {
    root: styled.div ``,
    __dark: styled.div ``,
    __light: styled.div ``,
};
