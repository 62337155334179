import { StrictMode } from 'react';
import { DemoCode } from '~/components/DemoCode';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { _CssRebase } from '~/modules/SDK/FrTemplate/v2/components/_CssRebase';
import { _GridArea } from '~/modules/SDK/FrTemplate/v2/components/_GridArea';
import { _GridDrawer } from '~/modules/SDK/FrTemplate/v2/components/_GridDrawer';
import { _GridModal } from '~/modules/SDK/FrTemplate/v2/components/_GridModal';
import { _GridRoot } from '~/modules/SDK/FrTemplate/v2/components/_GridRoot';
import { _HeadRebase } from '~/modules/SDK/FrTemplate/v2/components/_HeadRebase';
import { _LayoutRebase202309 } from '~/modules/SDK/FrTemplate/v2/components/_LayoutRebase202309';
import { _MediaRender } from '~/modules/SDK/FrTemplate/v2/components/_MediaRender';
import { _ThemeToggle } from '~/modules/SDK/FrTemplate/v2/components/_ThemeToggle';
import { _useMedia } from '~/modules/SDK/FrTemplate/v2/hooks/_useMedia';
import { _getAreasName } from '~/modules/SDK/FrTemplate/v2/utils/_getAreasName';
import { useElementAsComponent } from '~/pages/heineken_template/_private/useElementAsComponent';
import { __DEV__ } from '~/utils/__DEV__';
/**
 * ### 可以算是 template 的 v2 版本
 *
 * 用於取代 heineken_template 設計模式
 *
 * 透過 {@link FrTemplate.GridRoot} 和 {@link FrTemplate.GridArea} 你可以拼裝出各種、類似於 heineken_template 裡頭的
 * {@link TemplatePage}
 */
export const FrTemplate = {
    ...FrLogic.createContext({
        colorSchema: FrLogic.colorSchema(),
    }),
    /**
     * 動態返回當前 mediaQuery (desktop 或 phone)
     *
     * @example
     *   //
     *   const media = FrTemplate.useMedia()
     *
     *   media.isPc // true | false 動態根據當前頁面寬度而定
     */
    useMedia: _useMedia,
    /**
     * ### 產生具備 typing 和 namespace 的 css:`grid-area` 變數
     *
     * 讓你可以使用在 css:`grid-template-areas` 裡面的
     *
     * @example
     *   //
     *   // 第一個參數是給 `namespace` 用於區隔嵌套時的 `grid-area` 變數命名空間
     *   const areas = FrTemplate.getAreasName('NextPageLayout')
     *
     *   areas.NextPageLayout // 'NextPageLayout'
     *   areas.Main // 'NextPageLayout__Main'
     *   areas.Drawer1 // 'NextPageLayout__Drawer1'
     *   areas.Drawer2 // 'NextPageLayout__Drawer2'
     *
     *   const gridCss = css`
     *     grid-template-areas:
     *       '${areas.Drawer1} ${areas.Main} ${areas.Main}'
     *       '${areas.Drawer1} ${areas.Main} ${areas.Main}'
     *       '${areas.Drawer1} ${areas.Main} ${areas.Main}';
     *   `
     */
    getAreasName: _getAreasName,
    /** 用於拼裝出 XXXLayout 組件 */
    GridRoot: _GridRoot,
    /** 用於拼裝出 XXXLayout 組件 */
    GridArea: _GridArea,
    /** 用於拼裝出 XXXLayout 裡頭的 mantine:`<Drawer />` 組件 */
    GridDrawer: _GridDrawer,
    /** 用於拼裝出 XXXLayout 裡頭的 mantine:`<Modal />` 組件 */
    GridModal: _GridModal,
    /**
     * ### 動態根據當前 mediaQuery (desktop 或 phone) 來決定是否渲染給予的「子元素」
     *
     * @example
     *   //
     *   return (
     *     <Fragment>
     *       <FrTemplate.MediaRender isPhone>
     *         <FrTemplate.GridArea name={areas.Topbar}>
     *           <Topbar />
     *         </FrTemplate.GridArea>
     *
     *         <FrTemplate.GridDrawer drawer={state.drawer1}>
     *           <Sidebar />
     *         </FrTemplate.GridDrawer>
     *       </FrTemplate.MediaRender>
     *
     *       <FrTemplate.MediaRender isPc>
     *         <FrTemplate.GridArea name={areas.Drawer1}>
     *           <Sidebar />
     *         </FrTemplate.GridArea>
     *       </FrTemplate.MediaRender>
     *
     *       <FrTemplate.GridArea name={areas.Main}>
     *         <props.MainContent />
     *       </FrTemplate.GridArea>
     *     </Fragment>
     *   )
     */
    MediaRender: _MediaRender,
    /**
     * ### 我們的 `CSS Normalize` 的實現
     *
     * 只需要一次，並放置在最頂層的 XXXLayout 組件即可
     *
     * 內部已包含 npm:`Normalize.css`@8.0.1
     *
     * ### 注意
     *
     * 如果你已經使用 {@link _LayoutRebase202309} 那麼你將不再須要使用這個組件
     */
    CssRebase: _CssRebase,
    /**
     * ### 基本的 `<head />` 裡頭會有的 `<title />`、`<meta />`、`favicon` 配置
     *
     * ### 注意
     *
     * 如果你已經使用 {@link _LayoutRebase202309} 那麼你將不再須要使用這個組件
     *
     * @example
     *   //
     *   return (
     *     <Fragment>
     *       // 略...
     *       <FrTemplate.HeadRebase {...fr_agents.config} />
     *     </Fragment>
     *   )
     */
    HeadRebase: _HeadRebase,
    /**
     * ### 用於生成 XXXLayout 設計模式
     *
     * 內含 ThemeProvider、HeadRebase、CssRebase 組件群，提供快速生成 XXXLayout 的能力
     *
     * @example
     *   //
     *   // 大致結構
     *   return (
     *     <FrTemplate.LayoutRebase202309>
     *       {!me.hasLogin && <LoginForm />}
     *
     *       {me.hasLogin && (
     *         <FrTemplate.GridRoot>
     *           <FrTemplate.GridArea name={areas.Topbar} />
     *           <FrTemplate.GridDrawer drawer={state.drawer1} />
     *           <FrTemplate.GridArea name={areas.Main} />
     *         </FrTemplate.GridRoot>
     *       )}
     *     </FrTemplate.LayoutRebase202309>
     *   )
     */
    LayoutRebase202309: _LayoutRebase202309,
    ThemeToggle: _ThemeToggle,
    /**
     * ### 用於產生純淨的 NextPage 組件
     *
     * 設計目的是為了讓你可以在 index.page.tsx 中使用
     *
     * @example
     *   // 在 index.page.tsx 中使用
     *   export default FrTemplate.getNextPage(() => {
     *     if (fr_agents.is['futuresai@strategy_center_admin']) {
     *       return Layout
     *     }
     *   })
     *
     *   // 在 index.page.tsx 中使用
     *   // 或者是你想要傳入 props
     *   export default FrTemplate.getNextPage(() => {
     *     if (fr_agents.is['futuresai@strategy_center_admin']) {
     *       return <Layout MainContent={Main} />
     *     }
     *   })
     *
     *   // 在 index.page.tsx 中使用
     *   // 或者你還想更複雜一點
     *   export default FrTemplate.getNextPage(() => {
     *     if (fr_agents.is['futuresai@strategy_center_admin']) {
     *       return function NextPage() {
     *         return <Layout MainContent={Main} />
     *       }
     *     }
     *   })
     */
    getNextPage: (getComponentFunc) => {
        const _Component = getComponentFunc() ||
            function Hint() {
                return (<DemoCode>{`
            // 💡 請配置你的 index.page.tsx 示例
            export default FrTemplate.getNextPage(() => {
              return Layout
            })
          `}</DemoCode>);
            };
        const NextPageComponent = props => {
            const Component = useElementAsComponent(_Component);
            if (__DEV__) {
                // eslint-disable-next-line no-console
                console.log(`%cReact 目前以 <StrictMode> 嚴格模式渲染，請注意你的所有組件的 Effect 效果，將會重複達兩次，這是正常現象（僅限於 Dev）。當部署為 Production 後，此現象將會自動消失。`, 'font-size: 24px; background-color: #978500; color: black;');
            }
            return (<StrictMode>
          <FrTemplate.Provider>
            <Component />
          </FrTemplate.Provider>
        </StrictMode>);
        };
        return NextPageComponent;
    },
};
