import { css } from '@emotion/react';
import { clas } from '~/utils/clas';
export const _GridRoot = (props) => {
    return (<div className={clas([props.name, props.className])} css={css `
        display: grid;
        min-height: 100%;
      `}>
      {props.children}
    </div>);
};
_GridRoot.displayName = 'FrTemplate/v2/components/GridRoot';
